import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  TrendingUp,
  Star,
  Language,
  LocalFireDepartment
} from '@mui/icons-material';

const StatItem = ({ icon, value, label, color }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    mb: 2,
    p: 1,
    borderRadius: '8px',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateX(4px)',
      backgroundColor: `${color}08`
    }
  }}>
    <Box
      sx={{
        backgroundColor: `${color}15`,
        borderRadius: '12px',
        p: 1.5,
        mr: 2,
        display: 'flex',
        boxShadow: `0 2px 4px ${color}15`
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography 
        variant="h5" 
        component="div"
        sx={{ 
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif'
        }}
      >
        {value}
      </Typography>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{ 
          fontFamily: 'Arial, sans-serif',
          fontSize: '0.875rem'
        }}
      >
        {label}
      </Typography>
    </Box>
  </Box>
);

const StatsCard = ({ totalStats }) => {
  const {
    total_cards,
    total_starred,
    total_sources,
    streak_days
  } = totalStats;

  // Daily goal progress (assuming a goal of 5 cards per day)
  const dailyGoal = 5;
  const progress = (total_cards % dailyGoal) * 20; // Convert to percentage

  return (
    <Card 
      sx={{ 
        height: '100%',
        borderRadius: '5px',
        boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
        backgroundColor: '#ffffff'
      }}
    >
      <CardContent>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#333',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
            mb: 3
          }}
        >
          Overall Statistics
        </Typography>

        <StatItem
          icon={<TrendingUp sx={{ color: '#2196f3' }} />}
          value={total_cards}
          label="Total Cards Created"
          color="#2196f3"
        />

        <StatItem
          icon={<Star sx={{ color: '#ffc107' }} />}
          value={total_starred}
          label="Starred Cards"
          color="#ffc107"
        />

        <StatItem
          icon={<Language sx={{ color: '#4caf50' }} />}
          value={total_sources}
          label="Unique Sources"
          color="#4caf50"
        />

        <StatItem
          icon={<LocalFireDepartment sx={{ color: '#f44336' }} />}
          value={streak_days}
          label="Day Streak"
          color="#f44336"
        />

        <Box sx={{ mt: 3 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mb: 1
          }}>
            <Typography 
              variant="body2"
              sx={{ 
                fontFamily: 'Arial, sans-serif',
                color: '#333'
              }}
            >
              Daily Goal Progress
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                fontFamily: 'Arial, sans-serif',
                color: '#666'
              }}
            >
              {total_cards % dailyGoal}/{dailyGoal} cards
            </Typography>
          </Box>
          <Tooltip title={`${progress}% of daily goal`}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ 
                height: 8, 
                borderRadius: 5,
                backgroundColor: '#f0f0f0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#4CAF50'
                }
              }}
            />
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
