import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import api from '../../services/api';

const ChatWindow = () => {
  const { url } = useParams();
  const [highlights, setHighlights] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (url) {
      fetchHighlights();
    }
  }, [url]);

  useEffect(() => {
    if (selectedChat) {
      fetchChatMessages();
    }
  }, [selectedChat]);

  const fetchHighlights = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const response = await api.post('/get-highlight-list', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        data: { url: decodeURIComponent(url) }
      });
      setHighlights(response.data);
    } catch (error) {
      console.error('Error fetching highlights:', error);
    }
  };

  const fetchChatMessages = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const response = await api.post('/session', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        data: { session_id: selectedChat.chat_session_id }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  return (
    <Box sx={{ p: 3, display: 'flex', gap: 2 }}>
      {/* Highlights List */}
      <Paper sx={{ width: 300, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Highlighted Texts
        </Typography>
        <List>
          {highlights.map((highlight) => (
            <ListItem 
              button 
              key={highlight.chat_session_id}
              onClick={() => setSelectedChat(highlight)}
              selected={selectedChat?.chat_session_id === highlight.chat_session_id}
            >
              <ListItemText 
                primary={highlight.text.substring(0, 50) + '...'}
                secondary={new Date(highlight.timestamp).toLocaleString()}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Chat Messages */}
      <Paper sx={{ flex: 1, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Chat History
        </Typography>
        {selectedChat ? (
          <List>
            {messages.map((message, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={message.content}
                  secondary={`${message.sender} - ${new Date(message.timestamp).toLocaleString()}`}
                  sx={{
                    textAlign: message.sender === 'user' ? 'right' : 'left',
                    '& .MuiListItemText-primary': {
                      bgcolor: message.sender === 'user' ? '#e3f2fd' : '#f5f5f5',
                      p: 1,
                      borderRadius: 1,
                      display: 'inline-block'
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="textSecondary">
            Select a highlight to view the chat
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default ChatWindow; 