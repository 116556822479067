import axios from 'axios';
import { API_BASE_URL } from '../utils/constants';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add auth token interceptor
api.interceptors.request.use((config) => {
  const tokens = JSON.parse(localStorage.getItem('tokens'));
  if (tokens) {
    config.data = {
      ...config.data,
      request: {
        access_token: tokens.access_token,
        refresh_token: tokens.refresh_token,
      },
    };
  }
  return config;
});

export default api; 