import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const ChatList = () => {
  const [urls, setUrls] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const response = await api.post('/chat-urls', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        }
      });
      setUrls(response.data.urls);
    } catch (error) {
      console.error('Error fetching URLs:', error);
    }
  };

  const handleUrlClick = (url) => {
    navigate(`/chats/${encodeURIComponent(url)}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Chat History by URL
      </Typography>
      <List>
        {urls.map((url, index) => (
          <React.Fragment key={url}>
            <ListItem button onClick={() => handleUrlClick(url)}>
              <ListItemText 
                primary={new URL(url).hostname}
                secondary={url}
              />
            </ListItem>
            {index < urls.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ChatList; 