import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Chip,
  Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Construction } from '@mui/icons-material';

const Header = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('tokens');

  const handleLogout = () => {
    localStorage.removeItem('tokens');
    navigate('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Flashckard
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isLoggedIn ? (
            <>
              <Button color="inherit" onClick={() => navigate('/dashboard')}>
                Dashboard
              </Button>
              <Button color="inherit" onClick={() => navigate('/flashcards')}>
                Flashcards
              </Button>
              <Tooltip title="Chat feature is under development" arrow>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <Button 
                    color="inherit" 
                    disabled
                    startIcon={<Construction />}
                    sx={{
                      opacity: 0.8,
                      '&.Mui-disabled': {
                        color: 'white'
                      }
                    }}
                  >
                    Chats
                  </Button>
                  <Chip
                    label="Coming Soon"
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: -10,
                      right: -15,
                      backgroundColor: '#ff9800',
                      color: 'white',
                      fontSize: '0.65rem',
                      height: '18px',
                      fontWeight: 'bold',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      '&:hover': {
                        backgroundColor: '#f57c00'
                      }
                    }}
                  />
                </Box>
              </Tooltip>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button color="inherit" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button color="inherit" onClick={() => navigate('/register')}>
                Register
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header; 