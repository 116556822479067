import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  IconButton, 
  Grid,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  CircularProgress
} from '@mui/material';
import { Star, StarBorder, Delete, Search } from '@mui/icons-material';
import api from '../../services/api';

const FlashcardList = () => {
  const [cards, setCards] = useState([]);
  const [allUrls, setAllUrls] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUrl, setSelectedUrl] = useState(null);

  const fetchCards = async (loadMore = false) => {
    try {
      setIsLoading(true);
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      
      const response = await api.post('/get_cards', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        pagination: {
          page_size: 15,
          before_timestamp: loadMore ? oldestTimestamp : null
        }
      });

      const newCards = response.data.cards;
      setHasMore(response.data.has_more);
      setOldestTimestamp(response.data.oldest_timestamp);

      setCards(prevCards => loadMore ? [...prevCards, ...newCards] : newCards);

      updateUrlsList(loadMore ? [...cards, ...newCards] : newCards);
      
    } catch (error) {
      console.error('Error fetching cards:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUrlsList = (cardsArray) => {
    const urlStats = cardsArray.reduce((acc, card) => {
      const hostname = new URL(card.url).hostname;
      acc[card.url] = acc[card.url] || {
        url: card.url,
        hostname,
        count: 0
      };
      acc[card.url].count++;
      return acc;
    }, {});
    
    setAllUrls(Object.values(urlStats));
  };

  const observer = useRef();
  const lastCardRef = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchCards(true);
      }
    });
    
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  useEffect(() => {
    fetchCards();
  }, []);

  const handleStar = async (card) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const updatedCards = cards.map(c => 
        c.id === card.id ? { ...c, is_starred: !c.is_starred } : c
      );
      setCards(updatedCards);

      await api.post('/edit', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        data: {
          ...card,
          is_starred: !card.is_starred
        }
      });
    } catch (error) {
      console.error('Error updating card:', error);
      fetchCards();
    }
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const updatedCards = cards.filter(card => card.id !== id);
      setCards(updatedCards);

      await api.post('/delete', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        data: { id }
      });
    } catch (error) {
      console.error('Error deleting card:', error);
      fetchCards();
    }
  };

  const handleForceRefresh = () => {
    localStorage.removeItem('lastFetched');
    fetchCards();
  };

  const groupCardsByDate = (cards) => {
    return cards.reduce((groups, card) => {
      const date = new Date(card.timestamp).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(card);
      return groups;
    }, {});
  };

  const getUniqueUrls = () => allUrls;

  const filteredCards = cards.filter(card => {
    const matchesSearch = searchTerm === '' || 
      card.selected_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      card.generated_text.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesUrl = !selectedUrl || card.url === selectedUrl;
    return matchesSearch && matchesUrl;
  });

  const groupedCards = groupCardsByDate(filteredCards);

  const handleUrlClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ display: 'flex', gap: 3, p: 3 }}>
      <Paper sx={{ width: 280, p: 2, alignSelf: 'flex-start', position: 'sticky', top: 20 }}>
        <Typography variant="h6" gutterBottom>
          Sources
        </Typography>
        <List>
          <ListItem button onClick={() => setSelectedUrl(null)} selected={!selectedUrl}>
            <ListItemText primary="All Sources" />
            <Chip label={cards.length} size="small" />
          </ListItem>
          {getUniqueUrls().map(({ url, hostname, count }) => (
            <ListItem 
              button 
              key={url} 
              onClick={() => setSelectedUrl(url)}
              selected={url === selectedUrl}
            >
              <ListItemText primary={hostname} />
              <Chip label={count} size="small" />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Box sx={{ flex: 1 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            Flashcards
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search flashcards..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <Search sx={{ color: 'text.secondary', mr: 1 }} />,
            }}
            sx={{ mb: 3 }}
          />
        </Box>

        {Object.entries(groupedCards).map(([date, dateCards], index, array) => (
          <Box key={date} sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" color="text.secondary">
                {date}
              </Typography>
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
            <Grid container spacing={3}>
              {dateCards.map((card, cardIndex) => (
                <Grid 
                  item 
                  xs={12} 
                  sm={6} 
                  md={4} 
                  key={card.id}
                  ref={
                    index === array.length - 1 && 
                    cardIndex === dateCards.length - 1 ? 
                    lastCardRef : null
                  }
                >
                  <Card 
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 4,
                      }
                    }}
                  >
                    <CardContent sx={{ flex: 1 }}>
                      <Typography 
                        variant="h6" 
                        gutterBottom 
                        sx={{ 
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          mb: 2
                        }}
                      >
                        {card.selected_text}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {card.generated_text}
                      </Typography>
                      <Box sx={{ mt: 'auto' }}>
                        <Typography 
                          component="a"
                          variant="caption" 
                          display="block" 
                          sx={{ 
                            mb: 1,
                            color: 'primary.main',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline'
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                          onClick={() => handleUrlClick(card.url)}
                        >
                          <Box 
                            component="img" 
                            src={`https://www.google.com/s2/favicons?domain=${new URL(card.url).hostname}`}
                            sx={{ width: 16, height: 16 }}
                            alt=""
                          />
                          {new URL(card.url).hostname}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <IconButton onClick={() => handleStar(card)} size="small">
                            {card.is_starred ? <Star color="primary" /> : <StarBorder />}
                          </IconButton>
                          <IconButton onClick={() => handleDelete(card.id)} size="small">
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FlashcardList; 